
import React, { useState, useEffect } from 'react'
import PaidIcon from '../assets/images/paidmdlogo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBars, faLocationDot, faFile, faInbox, faFileLines, faAngleUp, faPowerOff, faStar } from '@fortawesome/free-solid-svg-icons';
import { getNotificationCount, getInitialValues, logout, getNotificationData, notificationsRead } from '../resources/api-constants'
import swal from 'sweetalert';
import { useSelector, useDispatch } from 'react-redux';
import { setUserDetails } from '../store/actions/userDetails';
import { setLoginValues } from '../store/actions/loginValues';
import { setLiveElements } from '../store/actions/liveElements';
import { setHxElements } from '../store/actions/hxElements';
import { setForumValues } from '../store/actions/forumValues';
import { RootState } from '../store/reducers/store';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/navbar.scss'

const Navbar: React.FC = () => {

    interface Notification {
        message: string;
        action: string;
        status: string;
        date: string;
        notification_id: number;
        type: string;
        type_of_act: string;
    }

    let sid = ''
    const login_info = localStorage.getItem('_login_info')

    if (login_info !== null) {
        sid = JSON.parse(login_info).sid
    } 

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userDetails = useSelector((state: RootState) => state.userDetails);
    const urls = useSelector((state: any) => state.urlValues);
    const loginVal = useSelector((state: any) => state.loginValues);
    
    const [showNotification, setShowNotification] = useState(false)
    const [isMenuVisible, setIsMenuVisible] = useState(false)
    const [notificationCount, setNotificationCount] = useState(0)
    const [notificationContent, setNotificationContent] = useState<Notification[]>([]);

    useEffect(() => {
        fetchInitialValues()
        
        // const intervalId = setInterval(fetchNotificationCountData, 60 * 1000); // Set interval to 1 minutes
        // return () => clearInterval(intervalId);
    }, [])

    const getNotificationContentData = async (userData: any) => {
        
        let timezone_offset_minutes = new Date().getTimezoneOffset();
        timezone_offset_minutes = timezone_offset_minutes == 0 ? 0 : -timezone_offset_minutes;

        const param = {
            username: userData.username,
            userid: userData.userid,
            timezone_offset: timezone_offset_minutes,
            sid: loginVal.sid
        }
        const res = await getNotificationData(param)
        if (res !== "error") {
            setNotificationContent(res.notifications);
        }
        // console.log(res);
    }

    const fetchInitialValues = async () => {
        const params = {}
        const res = await getInitialValues(params)
        dispatch(setUserDetails(res));
        fetchNotification(res)
    }

    const itemreaded = async (notification: any) => {
        let param = {
            username: userDetails.username,
            mark_notification: notification.notification_id
        }
        const res = await notificationsRead(param)
        if (res !== 'error') {
            let sidVal = '';
            if (sid === '') {
                sidVal = loginVal.sid;
            } else {
                sidVal = sid;
            }
            window.location.href = `${urls.forum}viewtopic.php?p=${notification.action}&sid=${sidVal}#p${notification.action}`;
        }
    }

    let notcont = (
        <div></div>
    )

    if (showNotification) {

        let sidVal = '';
        if (sid === '') {
            sidVal = loginVal.sid;
        } else {
            sidVal = sid;
        }

        notcont = (
            <>
                { (notificationContent && notificationContent.length !== 0) ?  (

                    <div className='has-notification'>
                        <div className='not-head'>
                            <span className='not-cont-label'>NOTIFICATIONS</span>
                            
                        </div>
                        
                        <hr />
                        {notificationContent.map((notification, index) => (
                            <React.Fragment key={notification.notification_id}>
                                <a style={{color: "black"}} href="" className='not-link'
                                onClick={() => { itemreaded(notification) }}
                                >
                                    {/* <div>
                                        <span style={{color: "#bec4c9"}} >Mark as read</span>
                                    </div> */}
                                    <div>
                                        {/* {(notification.type == '8') && (
                                            <img className='user-img' src=".{{notification.user_avatar}}" alt="User" />
                                        )} */}
                                        
                                        <img style={{width: "30px"}} src={`${urls.forum}ext/steve/postreactions/images/emoji/${notification.type_of_act}`} alt="" />
                                        
                                        {(notification.status=='0') && (
                                            <span style={{color: "#00acc2"}}>
                                                <FontAwesomeIcon icon={faStar} className="not-icon" />
                                                NEW : 
                                            </span>
                                        )}
                                        {(notification.type=='8' && notification.status=='0') && (
                                            <span style={{color: "#bec4c9"}}>New Reaction from </span>
                                        )}
                                        <span style={{color: "#bec4c9"}}>
                                            {notification.message}
                                        </span>

                                        <p className='not-date'>{notification.date}</p>
                                    </div>
                                    <hr />
                                </a>
                                
                            </React.Fragment>
                        ))}
                        
                        <a href={`${urls.forum}ucp.php?i=ucp_notifications&sid=${sidVal}`} style={{color: "#bec4c9"}}>See All</a>
                    </div>

                ) : (
                   
                    <div className='no-notification'>
                        <span className='not-label'>NOTIFICATIONS</span>
                        <hr />
                        <span className='no-not-content'>You have no notifications</span>
                        <hr />
                    </div>
                    
                )}
            </>
        )
    } else {
        notcont = (
            <div style={{display: 'none'}}></div>
        )
    }

    const fetchNotification = (userData: any) => {

        fetchNotificationCountData(userData)
        getNotificationContentData(userData)
        const intervalId = setInterval(() => { 
            fetchNotificationCountData(userData)
            getNotificationContentData(userData)
        }, 2 * 60 * 1000); // Set interval to 2 minutes
        return () => clearInterval(intervalId);
    }

    const fetchNotificationCountData = async (userData: any) => {
       
        const data = {
            username: userData.username,
            userid: userData.userid
        }
        const resp = await getNotificationCount(data);
        resp !== "error" && resp && setNotificationCount(resp.unread_count)
    }

    const notificationClick = () => {
        isMenuVisible && setIsMenuVisible(!isMenuVisible)
        setShowNotification(!showNotification)
    }

    const menuClick = () => {
        showNotification && setShowNotification(!showNotification)
        setIsMenuVisible(!isMenuVisible)
    }

    const HandleLogout = async () => {
        setIsMenuVisible(!isMenuVisible)
        swal({
            title: 'Are you sure you want to logout ?',
            // text: 'Are you sure you want to logout!',
            icon: 'warning',
            buttons: [true, 'Yes'],
            dangerMode: true
        }).then( async (willLogout) => {
            if (willLogout) {
                const req = {
                    sid: loginVal.sid
                }
                const res = await logout(req)
                if (res !== 'error') {
                    if (localStorage.getItem('_login_info') !== null) {
                        localStorage.clear()
                        // localStorage.removeItem('_login_info')
                        navigate('/')
                    } else {
                        navigate('/')
                    }
                } else {
                    console.log(res)
                }
            }
        })
    }

    const handleSearch = (searchId: string) => {
        if (sid === '') {
            window.location.href = urls.forum + 'search.php?search_id='+searchId+'&sid='+loginVal.sid;
        } else {
            window.location.href = urls.forum + 'search.php?search_id='+searchId+'&sid='+sid;
        }
    }

    return (
        <div className="navbar">
            <a className="navbar-brand" href="/home">
                <img src={PaidIcon} alt="paid.md logo" />
            </a>

            <div className="menu-and-notification">
                <span className="notification" onClick={ notificationClick }>
                    <FontAwesomeIcon icon={faBell} className="notify-icon" />{notificationCount}
                </span>

                <span className="poping-menu" onClick={ menuClick }>
                    <FontAwesomeIcon icon={faBars} className="menu-icon" />
                </span>

                {isMenuVisible && (
                    <>
                        <span className='triangleUp'></span>
                        <div className="menu-list">
                            <a href="#" className='menu-list-link' onClick={() => {handleSearch('egosearch')}}>
                                <div className="menu-list-items">
                                    <FontAwesomeIcon icon={faLocationDot} style={{paddingRight: '6%'}}/>My Posts
                                </div>
                            </a>
                            <a href="#" className='menu-list-link' onClick={() => {handleSearch('newposts')}}>
                                <div className="menu-list-items">
                                    <FontAwesomeIcon icon={faFile} style={{paddingRight: '6%'}}/>New Posts
                                </div>
                            </a>
                            <a href="#" className='menu-list-link' onClick={() => {handleSearch('unreadposts')}}>
                                <div className="menu-list-items">
                                    <FontAwesomeIcon icon={faInbox} style={{paddingRight: '6%'}}/>Unread Posts
                                </div>
                            </a>
                            <a href="#" className='menu-list-link' onClick={() => {handleSearch('unanswered')}}>
                                <div className="menu-list-items">
                                    <FontAwesomeIcon icon={faFileLines} style={{paddingRight: '6%'}}/>Unanswered Posts
                                </div>
                            </a>
                            <a href="#" className='menu-list-link' onClick={() => {handleSearch('active_topics')}}>
                                <div className="menu-list-items">
                                    <FontAwesomeIcon icon={faAngleUp} style={{paddingRight: '6%'}}/>Active Topics
                                </div>
                            </a>
                            <a href="#" className='menu-list-link ' style={{color: '#DC143C'}} onClick={HandleLogout}>
                                <div className="menu-list-items no-hover">
                                    <FontAwesomeIcon icon={faPowerOff} style={{paddingRight: '6%'}}/>Logout
                                </div>
                            </a>
                        </div>
                    </>
                )}
            </div>

            {notcont}

        </div>
    )
}

export default Navbar